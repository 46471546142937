import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import { useMediaQuery } from 'beautiful-react-hooks'

import anime from 'animejs/lib/anime.es.js'

import styles from './styles.module.scss'

export function ModalBody ({ children, show }) {
    const isMobile = useMediaQuery('(max-width: 767px)')
    const ref = useRef()
    const [mounted, setMounted] = React.useState(show)
    React.useEffect(() => {
        if (mounted && ref && ref.current) {
            if (show) {
                anime({
                    targets: ref.current,
                    keyframes: isMobile
                        ? [
                            {
                                translateY: 0,
                                translateX: 0,
                                opacity: 0.25,
                                bottom: '-120%'
                                // left: 0,
                                // right: 0
                            },
                            {
                                // translateY: 0,
                                // translateX: 0,
                                opacity: 1,
                                bottom: '0%'
                                // left: 0,
                                // right: 0
                            }
                        ]
                        : [
                            {
                                translateY: '50%',
                                translateX: '50%',
                                opacity: 0.25,
                                right: '-120%',
                                bottom: '50%'
                            },
                            {
                            // translateY: '50%',
                            // translateX: '50%',
                                opacity: 1,
                                right: '50%',
                                bottom: '50%'
                            }
                        ],
                    duration: 1000,
                    delay: isMobile ? 500 : 0,
                    easing: 'easeOutQuart',
                    autoplay: true
                })
            } else {
                anime({
                    targets: ref.current,
                    keyframes: isMobile
                        ? [
                            {
                                opacity: 1,
                                bottom: '0%',
                                left: 0,
                                translateY: {
                                    value: 0,
                                    duration: 0
                                },
                                translateX: {
                                    value: 0,
                                    duration: 0
                                }
                            },
                            {
                                opacity: 0,
                                bottom: '-120%',
                                left: 0,
                                translateY: {
                                    value: 0,
                                    duration: 0
                                },
                                translateX: {
                                    value: 0,
                                    duration: 0
                                }
                            }
                        ]
                        : [
                            {
                                opacity: 1,
                                right: '50%',
                                translateX: {
                                    value: '50%',
                                    duration: 0
                                },
                                translateY: {
                                    value: '50%',
                                    duration: 0
                                }
                            },
                            {
                                opacity: 0,
                                right: '120%',
                                translateX: {
                                    value: '50%',
                                    duration: 0
                                },
                                translateY: {
                                    value: '50%',
                                    duration: 0
                                }
                            }
                        ],
                    duration: 1000,
                    delay: 0,
                    easing: 'easeOutQuart',
                    autoplay: true,
                    complete: ({ completed }) => {
                        if (completed) {
                            setMounted(false)
                        }
                    }
                })
            }
        }
    }, [ref, setMounted, show, mounted, isMobile])

    React.useEffect(() => {
        if (show) {
            setMounted(true)
        }
    }, [show])

    return (
        <>
            {
                mounted && (
                    <div className={styles.modalBody} ref={ref}>
                        {children}
                    </div>
                )
            }
        </>
    )
}

export const ModalOverlay = ({ onClickOverlay, show }) => {
    const overlayRef = useRef()
    const [mounted, setMounted] = React.useState(show)
    React.useEffect(() => {
        if (mounted && overlayRef && overlayRef.current) {
            if (show) {
                window.document.body.style.overflow = 'hidden'
                document.getElementById('root').style.filter = 'blur(0.18em)'

                anime({
                    targets: overlayRef.current,
                    keyframes: [
                        { backgroundColor: 'rgba(8, 8, 8, 0.55)', opacity: 1 },
                        { backgroundColor: 'rgba(8, 8, 8, 0.55)', opacity: 1 }
                    ],
                    duration: 0,
                    delay: 0,
                    direction: 'normal'
                })
            } else {
                anime({
                    targets: overlayRef.current,
                    keyframes: [
                        { backgroundColor: 'rgba(8, 8, 8, 0.55)', opacity: 1 },
                        { backgroundColor: 'rgba(8, 8, 8, 0.55)', opacity: 1 }
                    ],
                    duration: 0,
                    delay: 1000,
                    easing: 'easeOutQuart',
                    autoplay: true,
                    complete: ({ completed }) => {
                        if (completed) {
                            setMounted(false)
                            window.document.body.style.overflow = 'scroll'
                            document.getElementById('root').style.filter = ''
                        }
                    }
                })
            }
        }
    }, [show, overlayRef, mounted])

    React.useEffect(() => {
        if (show) {
            setMounted(show)
        }
    }, [show])

    return (
        <>
            {mounted && <div className={styles.modalOverlay} ref={overlayRef} />}
        </>
    )
}

export default function ModalContainer ({ children, show, onClickOverlay }) {
    return ReactDOM.createPortal(
        <>
            <ModalOverlay onClickOverlay={onClickOverlay} show={show} />
            {children}
        </>,
        document.getElementById('modal-root')
    )
}
