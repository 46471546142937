
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns6a2b09dc1a21c84eb85b57f58075fc0fen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '6a2b09dc1a21c84eb85b57f58075fc0f', flattenObject(ns6a2b09dc1a21c84eb85b57f58075fc0fen_US), true, true);
        
            import ns6a2b09dc1a21c84eb85b57f58075fc0fen from './en.yaml'
            i18n.addResourceBundle('en', '6a2b09dc1a21c84eb85b57f58075fc0f', flattenObject(ns6a2b09dc1a21c84eb85b57f58075fc0fen), true, true);
        
        export default '6a2b09dc1a21c84eb85b57f58075fc0f';
    