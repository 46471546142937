// @flow
import React from 'react'
import Step1 from 'components/LeadGenForm/Step1'
import Step2A from 'components/LeadGenForm/Step2A'
import Step2B from 'components/LeadGenForm/Step2B'
import ModelPickerSwitcher from 'components/ModelPickerSwitcher'

import { Store } from 'Store'
import { updateLead } from 'Actions'
import { Redirect } from 'react-router-dom'

import ModalContainer, { ModalBody } from 'components/ModalContainer'
import RegisterCode from 'components/RegisterCode'

export default props => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const [modalIsOpen, setModalIsOpen] = React.useState(!state.lead)
    const [modal2IsOpen, setModal2IsOpen] = React.useState(true)

    const handleCloseOverlay = React.useCallback(() => {
        setModalIsOpen(false)
    }, [setModalIsOpen])

    const handleModal2Close = React.useCallback(() => {
        setModal2IsOpen(false)
        setTimeout(() => {
            handleCloseOverlay()
        }, 50)
    }, [handleCloseOverlay, setModal2IsOpen])

    if (submitted) {
        return <Redirect to={`${window.location.pathname}/step-2${window.location.search}`} push />
    }

    return (
        <>
            <ModalContainer show={modalIsOpen}>
                <ModalBody show={modalIsOpen && !state.lead}>
                    <RegisterCode/>
                </ModalBody>

                <ModalBody show={modalIsOpen && state.lead && modal2IsOpen}>
                    <Step1 onSubmit={handleModal2Close}/>
                </ModalBody>
            </ModalContainer>

            {(state.campaign.variations.ModelPicker === 'single') ? (
                <Step2A
                    onSubmit={async (data, done) => {
                        try {
                            let lead = {
                                fields: {
                                    ...state.lead.fields,
                                    ...data
                                },
                                isComplete: false
                            }
                            await updateLead(dispatch, state.code, lead)
                            setSubmitted(true)
                        } catch (err) {
                            alert('Oops something went wrong')
                            console.error(err)
                            done(err)
                        }
                    }}
                    location={props.location}
                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                />
            ) : (
                <Step2B
                    onSubmit={async (data, done) => {
                        try {
                            let lead = {
                                fields: {
                                    ...state.lead.fields,
                                    ...data
                                },
                                isComplete: false
                            }
                            await updateLead(dispatch, state.code, lead)
                            setSubmitted(true)
                        } catch (err) {
                            alert('Oops something went wrong')
                            console.error(err)
                            done(err)
                        }
                    }}
                    location={props.location}
                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                />
            )}
            <ModelPickerSwitcher />
        </>
    )
}
