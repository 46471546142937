// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import HeaderWithVoucher from 'components/HeaderWithVoucher'
import BottomBar from 'components/BottomBar'

import { Store } from 'Store'
import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

const Success = ({
    location,
    theme
}) => {
    const { state } = React.useContext(Store)
    const { t } = useTranslation(transDomain)

    if (!state.lead) {
        return <Redirect to={`${location.pathname.replace('/success', '')}${location.search}`} push />
    }

    const model = state.campaign.models.find(m => m.make === state.lead.fields.newVehicleMake && m.name === state.lead.fields.newVehicleModel && m.year === state.lead.fields.newVehicleModelYear)
    const email = state.lead.fields.email
    return (
        <>
            <div className={styles.layoutWrapper}>
                <div className={styles.stepContainer}>
                    <HeaderWithVoucher />
                </div>
            </div>

            <div className={styles.information}>
                {t('information', {
                    year: model.year,
                    name: model.name,
                    clientName: state.campaign.client.name
                })}
            </div>

            <BottomBar forceStatic>
                <div className={styles.emailText}>
                    <Trans i18nKey="emailText" t={t} values={{
                        email
                    }}>
                        Your confirmation email has been sent to:&nbsp;<strong> {email}</strong>
                    </Trans>
                </div>
            </BottomBar>
        </>
    )
}

export default withTheme(supportedThemes)(Success)
