// @flow
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Store } from 'Store'

import OfferTag from 'components/OfferTag'
import { makeDateFormatters } from 'i18n/DateFormat'
import Countdown from 'components/Countdown'

import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

const TopBar = ({
    theme,
    t,
}) => {
    const { state } = React.useContext(Store)
    const { firstName, lastName } = state.lead?.fields || { firstName: '', lastName: '' }

    const dateFormatter = React.useMemo(() => {
        return makeDateFormatters(state.locale, 'long', null)
    }, [state.locale])

    return (
        <div className={cn(styles.container, {
            [styles.fixed]: false
        })} style={{
            '--primary-font': theme.primaryFont
        }}>
            <div className={styles.userInfo}>
                Customer ID:&nbsp;<strong>{firstName} {lastName}</strong>
            </div>
            <div className={styles.innerContainer}>
                <div className={styles.offerContent}>
                    <div
                        className={styles.logo}
                        style={{
                            width: theme.logoWidth,
                            height: theme.logoHeight,
                            backgroundImage: `url(${theme.logo})`
                        }}
                    />
                    <OfferTag />
                </div>

                <div className={styles.counter}>
                    <span>{t('expire')}&nbsp;<strong>{dateFormatter.format(state.campaign.expiryDate, 'long')}.</strong></span>
                    <div><Countdown date={state.campaign.expiryDate} small /></div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(TopBar))
