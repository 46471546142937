// @flow
import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { Store } from 'Store'
import { fetchCampaign } from 'Actions'

import Expired from 'pages/Expired'
import Landing from 'pages/Landing'
import Step2 from 'pages/Step2'

import Welcome from 'pages/Welcome'
import Success from 'pages/Success'

import Footer from 'components/Footer'
import TopBar from 'components/TopBar'
import ThemeWrapper from 'components/ThemeWrapper'
import CampaignNotFound from './components/CampaignNotFound'
import PromoHouse from './components/PromoHouse'
import Favicon from 'components/Favicon'
import PromoService from './components/PromoService'
import OnRouterChange from 'components/OnRouterChange'

const IS_CAMPAIGN_URL = /^\/.+$/.test(window.location.pathname)

const AppRoute = ({ component: Component, layout: Layout = MainLayout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
            <OnRouterChange />
        </Layout>
    )}/>
)

const MainLayout = props => {
    return (
        <ThemeWrapper>
            <TopBar />
            {props.children}
            <Footer/>
        </ThemeWrapper>
    )
}

const NoLayout = (props) => (<>{props.children}</>)

export default function App () {
    const { state, dispatch } = React.useContext(Store)

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null && state.errors.campaignNotFound !== true) {
            fetchCampaign(dispatch)
        }

        if (state.campaign) {
            document.title = `${state.campaign.name}`
        }
    })

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null) return
        setTimeout(() => {
            document.body.classList.remove('loading')
        }, 500)
    }, [state.campaign])

    if (state.errors.campaignNotFound) {
        return <CampaignNotFound/>
    }

    if (IS_CAMPAIGN_URL && state.campaign === null) {
        return <h1>Loading</h1>
    }

    if (state.campaign && state.campaign.expired) {
        return <Expired />
    }

    return (
        <Suspense fallback={<div>loading...</div>}>
            <BrowserRouter>
                <Switch>
                    <AppRoute exact path='/' component={Welcome} layout={NoLayout} />
                    <AppRoute exact path='/:url' component={Landing} />
                    <AppRoute exact path='/:url/step-2' component={Step2} />
                    <AppRoute exact path='/:url/success' component={Success} />
                    <AppRoute exact path="/:url/promo-voucher/service" layout={NoLayout} component={PromoService} />
                    <AppRoute exact path='/:url/promo-voucher/house' layout={NoLayout} component={PromoHouse} />
                </Switch>
            </BrowserRouter>
            {state.campaign && (
                <Favicon />
            )}
        </Suspense>
    )
}
