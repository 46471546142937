// @flow
import React from 'react'
import cn from 'classnames'
import queryString from 'query-string'

import { Store } from 'Store'

import styles from './styles.module.scss'

const modelPickerVariations = [
    {
        label: 'A',
        propertyName: 'single'
    },
    {
        label: 'B',
        // propertyName: null
    }
]

export default function ModelPickerSwitcher () {
    const { state, dispatch } = React.useContext(Store)
    const query = queryString.parse(window.location.search)

    const handleClick = React.useCallback(async e => {
        e.preventDefault()
        dispatch({
            type: 'SET_MODEL_PICKER_VARIATION',
            payload: e.target.dataset['variation']
        })
    }, [dispatch])

    if (typeof query['demo'] === 'undefined') return null

    return (
        <div className={styles.switcher}>
            <div className={cn(styles.switcherBg, {
                [styles.overflow]: state.campaign.locales.supported.length > 2
            })}>
                {modelPickerVariations.map((variation, i) => (
                    <div
                        key={i}
                        className={cn(styles.button, {
                            [styles.active]: state.campaign.variations.ModelPicker === variation.propertyName
                        })}
                        data-variation={variation.propertyName}
                        onClick={handleClick}>
                        {variation.label}
                    </div>
                ))}
            </div>
        </div>
    )
}
