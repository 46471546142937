// @flow
import * as React from 'react'
import RegisterUserForm from 'components/RegisterUserForm'

import { Store } from 'Store'
import { updateLead } from 'Actions'

const Step1 = ({
    onSubmit
}) => {
    const { state, dispatch } = React.useContext(Store)
    return (
        <>
            <RegisterUserForm
                onSubmit={async (data, done) => {
                    try {
                        let lead = {
                            fields: {
                                ...state.lead.fields,
                                ...data
                            },
                            isComplete: false
                        }
                        await updateLead(dispatch, state.code, lead)
                        onSubmit()
                    } catch (err) {
                        alert('Oops something went wrong')
                        console.error(err)
                        done(err)
                    }
                }}
                initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
            />
        </>
    )
}

export default Step1
