// @flow
import React from 'react'
import { withTranslation } from 'react-i18next'
import cn from 'classnames'
import * as Form from 'components/Form'
import { useMediaQuery } from 'react-responsive'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function getDocHeight () {
    return document.getElementById('root').offsetHeight || 1000
}

const BottomBar = ({
    fixed,
    t,
    theme,
    step,
    isLoading,
    forceStatic,
    children
}) => {
    const [isFixed, setFixed] = React.useState(fixed)
    const smallHeight = useMediaQuery({ maxHeight: 1044 })

    const getMinScroll = React.useCallback(() => {
        if (smallHeight) {
            if (isFixed) {
                return getDocHeight() - document.documentElement.clientHeight + 88 - 182
            } else {
                return getDocHeight() - document.documentElement.clientHeight - 182
            }
        }
        return null
    }, [isFixed, smallHeight])

    const handleScroll = React.useCallback(e => {
        const minScroll = getMinScroll()
        if (!minScroll) return setFixed(false)

        if (window.scrollY >= minScroll) {
            setFixed(false)
        } else {
            setFixed(true)
        }
    }, [setFixed, getMinScroll])

    React.useEffect(() => {
        if (!fixed || forceStatic) return () => ({})
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll, fixed, forceStatic])

    return (
        <>
            <div className={cn(styles.container, {
                [styles.static]: !isFixed,
                [styles.forceStatic]: forceStatic
            })} style={{
                backgroundColor: theme.backgroundColor,
                '--primary-font': theme.primaryFont
            }}>
                <div className={styles.innerContainer}>
                    {children
                        ? <>{children}</>
                        : (
                            <>
                                <div className={styles.stepsContainer} style={{
                                    '--step-color': theme.stepColor,
                                    '--active-step-color': theme.activeStepColor
                                }}>
                                    <span className={cn(styles.step, {
                                        [styles.active]: step === 1
                                    })}>
                                        {t('step_1')}
                                    </span>
                                    <span className={cn(styles.step, {
                                        [styles.active]: step === 2
                                    })}>
                                        {t('step_2')}
                                    </span>
                                </div>
                                <div styles={styles.btn}>
                                    <Form.Submit
                                        isLoading={isLoading}
                                        style={{
                                            backgroundColor: theme.buttonBackgroundColor,
                                            color: theme.buttonTextColor,
                                            '--color-loader': `url(${theme.loader})`
                                        }}
                                        label={t(`continue.${step}`)}
                                    />
                                </div>
                            </>
                        )}
                </div>
            </div>
        </>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(BottomBar))
