
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns8e89db74b4824229119373774a746823en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '8e89db74b4824229119373774a746823', flattenObject(ns8e89db74b4824229119373774a746823en_US), true, true);
        
            import ns8e89db74b4824229119373774a746823en from './en.yaml'
            i18n.addResourceBundle('en', '8e89db74b4824229119373774a746823', flattenObject(ns8e89db74b4824229119373774a746823en), true, true);
        
        export default '8e89db74b4824229119373774a746823';
    