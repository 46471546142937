// @flow
import React from 'react'

import { Store } from 'Store.js'
import { withTranslation } from 'react-i18next'
import cn from 'classnames'
import { Currency, Percent } from 'i18n/NumberFormatter'
import LoadingIndicator from 'components/LoadingIndicator'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import isShownNotExact from '../../util/isShownNotExact'
import is360Disabled from 'util/is360Disabled'
import get360VehicleImageUrl from 'util/get360VehicleImageUrl'
import getVehicleImageUrl from 'util/getVehicleImageUrl'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

const maxFrameHeight = 480
const maxFrameCount = 36
const minFrameCount = 1
const maxRangeToSlide = 10
const defaultRangeToSlide = 2.5

function CarView ({
    theme,
    startingPrice,
    title,
    voucherValue,
    year,
    make,
    segment,
    name,
    color,
    onLoad
}) {
    const ref = React.useRef(null)
    const { state } = React.useContext(Store)
    const [ swiped, setSwiped ] = React.useState(false)
    const [ disabled, setDisabled ] = React.useState(false)
    const [ xFrameCount, setXframeCount ] = React.useState(maxFrameCount)
    const [ rangeToSlide, setRangeToSlide ] = React.useState(defaultRangeToSlide)
    const [ swiping, setSwiping ] = React.useState(false)
    const [ clientXBeging, setClientXBeging ] = React.useState(0)
    const [ step, setStep ] = React.useState(0)
    const [ mouseDown, setMouseDown ] = React.useState(false)
    const [ loadedUri, setLoadedUri ] = React.useState(false)
    const [ frameH, setFrameH ] = React.useState(0)
    const [ image, setImage ] = React.useState(null)
    const [ staticImage, setStaticImage ] = React.useState(null)

    const percentFormatter = Percent(state.locale)
    const currencyFormatter = Currency(state.currency, state.locale)

    const incentives = state.campaign.incentives.manufacturer

    const handleRange = React.useCallback(event => {
        setStep(event.target.value - 1)
    }, [ setStep ])


    const modelNotExact = isShownNotExact(name, make, year)

    const handleOnTouchStart = React.useCallback(e => {
        setSwiped(false)
        setSwiping(false)
        setClientXBeging(e.touches[0]?.clientX)
        setMouseDown(true)
    }, [])

    const handleOnTouchMove = React.useCallback(e => {
        setSwiping(true)
        let currentStep = step
        const clientX = e.touches[0]?.clientX
        const direction = clientXBeging - clientX
        if (clientXBeging && mouseDown) {
            if (Math.abs(direction) >= rangeToSlide) {
                if (direction > 0) {
                    if (currentStep + 1 >= xFrameCount) {
                        setStep(xFrameCount - 1)
                    } else {
                        setStep(currentStep + 1)
                    }
                } else {
                    if (currentStep - 1 <= 0) {
                        setStep(0)
                    } else {
                        setStep(currentStep - 1)
                    }
                }
                setClientXBeging(e.touches[0]?.clientX)
            }
        }
    }, [step, clientXBeging, mouseDown, rangeToSlide, xFrameCount])

    const handleOnTouchUp = React.useCallback(e => {
        const clientX = e.touches[0]?.clientX
        const direction = clientXBeging - clientX
        if (swiping && !swiped) {
            let currentStep = step
            if (direction > 0) {
                if (currentStep + 1 >= xFrameCount) {
                    setStep(xFrameCount - 1)
                } else {
                    setStep(currentStep + 1)
                }
            } else {
                if (currentStep - 1 <= 0) {
                    setStep(0)
                } else {
                    setStep(currentStep - 1)
                }
            }

            setSwiping(false)
            setSwiped(true)
            setClientXBeging(null)
            setMouseDown(false)
        }
    }, [clientXBeging, swiping, swiped, step, xFrameCount])

    const handleOnMouseDown = React.useCallback(e => {
        setSwiped(false)
        setSwiping(false)
        setClientXBeging(e.clientX)
        setMouseDown(true)
    }, [ setSwiped, setMouseDown ])

    const handleMouseMove = React.useCallback(e => {
        setSwiping(true)
        let currentStep = step
        const clientX = e.clientX
        const direction = clientXBeging - clientX
        if (clientXBeging && mouseDown) {
            if (Math.abs(direction) >= rangeToSlide) {
                if (direction > 0) {
                    if (currentStep + 1 >= xFrameCount) {
                        setStep(xFrameCount - 1)
                    } else {
                        setStep(currentStep + 1)
                    }
                } else {
                    if (currentStep - 1 <= 0) {
                        setStep(0)
                    } else {
                        setStep(currentStep - 1)
                    }
                }
                setClientXBeging(e.clientX)
            }
        }
    }, [step, clientXBeging, mouseDown, rangeToSlide, xFrameCount])

    const handleMouseUp = React.useCallback(e => {
        const clientX = e.clientX
        const direction = clientXBeging - clientX
        if (swiping && !swiped) {
            let currentStep = step
            if (direction > 0) {
                if (currentStep + 1 >= xFrameCount) {
                    setStep(xFrameCount - 1)
                } else {
                    setStep(currentStep + 1)
                }
            } else {
                if (currentStep - 1 <= 0) {
                    setStep(0)
                } else {
                    setStep(currentStep - 1)
                }
            }

            setSwiping(false)
            setSwiped(true)
            setClientXBeging(null)
            setMouseDown(false)
        }
    }, [clientXBeging, swiping, swiped, step, xFrameCount])

    React.useEffect(() => {
        const imageUrl = get360VehicleImageUrl(make, name, year, 'jpg')

        if (is360Disabled(imageUrl)) {
            setDisabled(true)
            setLoadedUri(false)
            setStaticImage(getVehicleImageUrl(make, name, year, color, 'front_34', 'm', segment))
            return
        }

        setStaticImage(null)
        setDisabled(false)
        setSwiped(false)
        setSwiping(false)
        setClientXBeging(0)
        setStep(0)
        setMouseDown(false)
        setLoadedUri(false)
        setFrameH(0)
        setImage(imageUrl)
    }, [ make, name, year, segment, color, onLoad ])

    React.useEffect(() => {
        const refEvent = () => {
            if (ref && loadedUri && ref.current) {
                const xFrame = ref.current.naturalHeight / maxFrameHeight

                if (xFrame <= 1) {
                    setXframeCount(1)
                    setLoadedUri(true)
                    return
                }

                if (xFrame !== maxFrameCount) {
                    setXframeCount(xFrame)
                    setRangeToSlide(maxRangeToSlide)
                } else {
                    setXframeCount(maxFrameCount)
                    setRangeToSlide(defaultRangeToSlide)
                }

                setFrameH(ref.current.height / xFrame)
            }
        }

        setTimeout(refEvent, 250)

        window.addEventListener('orientationchange', refEvent)
        window.addEventListener('resize', refEvent)

        return () => {
            window.removeEventListener('resize', refEvent)
            window.removeEventListener('orientationchange', refEvent)
        }
    }, [ ref, loadedUri ])

    const handleLoadedUri = React.useCallback(() => {
        setLoadedUri(true)
        onLoad()
    }, [ onLoad, setLoadedUri ])

    const handleOnError = React.useCallback(e => {
        setImage(null)
        setDisabled(true)
        setStaticImage(getVehicleImageUrl(make, name, year, color, 'front_34', 'm', segment))
        setXframeCount(1)
        setLoadedUri(true)
    }, [make, name, year, color, segment])

    return (
        <div className={cn(styles.carView, {
            [styles.disabled]: disabled,
            [styles.singleFrame]: xFrameCount <= 1
        })} style={{
            '--primary-font': theme.primaryFont,
            '--primary-color': theme.primaryColor
        }}>
            <div className={styles.title}>{title}</div>

            <div className={styles.car}>
                <div
                    className={styles.imgSet}
                >
                    {(!disabled && image) &&
                        <img
                            key={image}
                            style={{
                                top: (step * -frameH),
                                opacity: loadedUri ? 1 : 0.25
                            }}
                            ref={ref}
                            src={image}
                            alt={title}
                            onLoad={handleLoadedUri}
                            onError={handleOnError}
                        />
                    }

                    {(xFrameCount > 1) && (
                        <div
                            className={styles.controllerArea}
                            onMouseDownCapture={disabled ? null : handleOnMouseDown}
                            onMouseMoveCapture={disabled ? null : handleMouseMove}
                            onBlur={disabled ? null : handleMouseUp}
                            onMouseOut={disabled ? null : handleMouseUp}
                            onMouseLeave={disabled ? null : handleMouseUp}
                            onMouseUpCapture={disabled ? null : handleMouseUp}
                            onTouchStart={disabled ? null : handleOnTouchStart}
                            onTouchMove={disabled ? null : handleOnTouchMove}
                            onTouchCancel={disabled ? null : handleOnTouchUp}
                            onTouchEnd={disabled ? null : handleOnTouchUp}
                        />
                    )}

                {modelNotExact && <span className={styles.shownMessage}>*Model shown is not exact</span>}
                </div>
                

                {!loadedUri && <LoadingIndicator />}

                {disabled && (
                    <div className={styles.disabledStaticContainer}>
                        <img onLoad={handleLoadedUri} alt={title} key={staticImage} src={staticImage} className={styles.staticImage}/>
                    </div>
                )}

                {(xFrameCount > 1) && <div className={styles.barContainer}>
                    <input
                        value={step + 1}
                        className={styles.bar}
                        type='range'
                        onChange={disabled ? null : handleRange}
                        min={minFrameCount}
                        max={xFrameCount}
                        style={{
                            '--thumb-width': `${100 / 8}%`,
                            '--thumb-color': theme.secondaryColor
                        }}
                    />

                    <div className={styles.info}>
                        Get a 360° look at the {title}
                    </div>
                </div>}
            </div>

            <div className={styles.separator} />

            <div className={styles.summary}>
                <div className={styles.summaryTitle}>{title}</div>

                <div className={styles.content}>
                    {startingPrice > 0 && (
                        <div className={styles.contentItem}>
                            Starting at price:
                            <strong>{currencyFormatter.format(startingPrice)}*</strong>
                        </div>
                    )}

                    <div className={styles.contentItem} style={{
                        color: theme.secondaryColor
                    }}>
                        Voucher value:
                        <strong>{currencyFormatter.format(voucherValue)}</strong>
                    </div>
                </div>

                {incentives && (
                    <div className={styles.incentivesWrapper}>
                        {!theme.hiddenInterestRate && (
                            <div className={styles.rates}>
                                Rates as low as <strong>{percentFormatter.format((Number.parseFloat(incentives.interestRate).toPrecision(3) / 100) || 0)}</strong>
                            </div>
                        )}

                        {(incentives.cashBack > 0) && (
                            <div className={styles.incentives}>
                                Cash incentives up to <strong>{currencyFormatter.format(incentives.cashBack)}*</strong>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(CarView))
