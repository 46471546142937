// @flow
import * as React from 'react'

import * as Form from 'components/Form'

import { Store } from 'Store'
import withForm from 'components/Form/Helpers/FormHOC'

import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import SummaryFooter from 'components/SummaryFooter'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function RegisterUserForm ({
    t,
    theme,
    isLoading,
    handleFieldChange,
    values
}) {
    const { state } = React.useContext(Store)

    const name = `${state.lead.fields.firstName}`

    const handleFirstName = React.useCallback(value => {
        return handleFieldChange('firstName', value)
    }, [ handleFieldChange ]) 

    const handleLastName = React.useCallback(value => {
        return handleFieldChange('lastName', value)
    }, [ handleFieldChange ])

    const handleEmail = React.useCallback(value => {
        return handleFieldChange('email', value)
    }, [ handleFieldChange ])

    const handlePhone= React.useCallback(value => {
        return handleFieldChange('mobilePhone', value)
    }, [ handleFieldChange ])

    return (
        <div className={styles.registerUserForm} style={{
            '--primary-font': theme.primaryFont,
        }}>
            <div
                className={styles.title}
                style={{
                    color: theme.titleColor
                }}
            >
                <Trans i18nKey="title" values={{ name }} t={t}>
                    Hello, {name}.
                </Trans>
            </div>

            <span className={styles.expireInfo} style={{ color: theme.expireInfoColor }}>
                Please confirm your information.
            </span>

            <div className={styles.formContainer}>
                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={1}>
                            <Form.Field
                                placeholder='First name'
                                required
                                onChange={handleFirstName}
                                defaultValue={values.firstName}
                                type='text'
                            />
                        </Form.RowColumn>
                        <Form.RowColumn size={1}>
                            <Form.Field
                                placeholder='Last name'
                                required
                                type='text'
                                onChange={handleLastName}
                                defaultValue={values.lastName}
                            />
                        </Form.RowColumn>
                        <Form.RowColumn size={1}>
                            <Form.Field
                                placeholder='Email address'
                                type='email'
                                required
                                onChange={handleEmail}
                                defaultValue={values.email}
                            />
                        </Form.RowColumn>
                        <Form.RowColumn size={1}>
                            <Form.Field
                                placeholder='Phone number'
                                type='phone'
                                required
                                onChange={handlePhone}
                                defaultValue={values.mobilePhone}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn>
                            <Form.Submit
                                style={{ ...theme.button, marginTop: '20px' }}
                                isLoading={isLoading}
                                disabled={isLoading}
                                label={t('continue')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            </div>

            <SummaryFooter />
        </div>
    )
}

export default withTranslation(transDomain)(withForm()(withTheme(supportedThemes)(RegisterUserForm)))
