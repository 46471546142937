// @flow

const VIDEOS = {
    base: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/base.mp4',
    ford: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/ford.mp4',
    honda: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/honda.mp4',
    hyundai: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/hyundai.mp4',
    kia: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/kia.mp4',
    nissan: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/nissan.mp4',
    toyota: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/toyota.mp4',
}

export function makeCampaign (style: string) {
    return {

        videos: {
            landingPage: VIDEOS[style] || VIDEOS.base
        },
        extraAssistanceRequest: {
            options: [
                'Video chat from a sales representative',
                'Test drive from home',
                'Virtual walk around of your vehicle of interest',
            ]
        },
        variations: {
            // ModelPicker: 'single'
        }
    }
}
