// @flow
import React from 'react'
import { withTranslation } from 'react-i18next'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

const OfferTag = ({
    theme,
    t,
}) => {

    return (
        <span
            className={styles.offerTag}
            style={{
                backgroundColor: theme.backgroundColor,
                color: theme.color,
                '--primary-font': theme.primaryFont,
            }}
        >
            {t('offer')}
        </span>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(OfferTag))
