// @flow
import * as React from 'react'
import LeadGenForm from 'components/LeadGenForm/Step3'
import { Store } from 'Store'
import { updateLead } from 'Actions'

import { Redirect } from 'react-router-dom'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-2', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-2', 'success')}${props.location.search}`} push />
    }

    return (
        <>
            <LeadGenForm
                onSubmit={async (data, done) => {
                    try {
                        let lead = {
                            fields: {
                                ...state.lead.fields,
                                ...data
                            },
                            isComplete: true
                        }
                        await updateLead(dispatch, state.code, lead)
                        setSubmitted(true)
                    } catch (err) {
                        alert('Oops something went wrong')
                        console.error(err)
                        done(err)
                    }
                }}
                initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
            />
        </>
    )
}
