// @flow

import React, { useRef, useState, useContext } from 'react'

import { Trans, withTranslation } from 'react-i18next'
import queryString from 'query-string'
import Button from 'components/Button'
import { Store } from 'Store'
import { fetchLead } from 'Actions'
import ReactTooltip from 'react-tooltip'

import withTheme from 'hoc/withTheme'
import type { Theme } from './themes/__supportedThemes'
import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

type Props = {
    theme: Theme
}

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

function RegistrationForm (props: Props) {
    const { t, theme } = props
    const [code, setCode] = useState(queryString.parse(window.location.hash)['code'] || '')
    const [isLoading, setIsLoading] = useState(false)
    const [invalidCode, setInvalidCode] = useState(false)
    const toolRef = useRef(null)
    const { dispatch } = useContext(Store)

    return (<form onSubmit={async e => {
        e.preventDefault()
        setIsLoading(true)
        try {
            await fetchLead(dispatch, code)
        } catch (err) {
            console.error(err)
            setInvalidCode(true)
            setIsLoading(false)
        }
    }}>
        <div className={styles.registrationFormContainer} style={{
            '--primary-font': theme.primaryFont,
            '--placeholder-color': theme.placeholderColor
        }}>

            {(!isIE11 && toolRef && toolRef.current) && (
                <ReactTooltip
                    type='info'
                    clickable
                    delayHide={800}
                    isCapture
                    className={styles.tooltip}
                />
            )}

            <div className={styles.formInputcontainer}>
                <div className={styles.inputContainer}>
                    <input
                        type="text"
                        autoFocus
                        placeholder={t('placeholder')}
                        name="code"
                        style={theme.input}
                        className={styles.input}
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        required
                    />
                    <InfoIcon ref={toolRef} color={theme.tooltipColor} />
                </div>

                <Button
                    isSubmit
                    style={{ ...theme.button }}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    {t('continue')}
                </Button>
            </div>
        </div>

        {
            invalidCode &&
                <div className={styles.invalidLabel}>
                    <Trans i18nKey='wrongCode'>You have entered an invalid code</Trans>
                </div>
        }
    </form>)
}

const InfoIcon = React.forwardRef((props, ref) => (
    <svg
        width={14}
        height={14}
        viewBox="0 0 14 14"
        data-tip="Customer code is in your letter, email or text."
        {...props}
        ref={ref}
    >
        <g fill="none" fillRule="evenodd">
            <circle stroke={props.color} cx={7} cy={7} r={6.5} />
            <text fontFamily="Helvetica" fontSize={10} fill={props.color}>
                <tspan x={5.889} y={11}>
                    {'i'}
                </tspan>
            </text>
        </g>
    </svg>
))

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegistrationForm))
