// @flow

import * as React from 'react'
import withTheme from 'hoc/withTheme'

import supportedThemes, { type Theme } from './themes/__supportedThemes'

type Props = {
    width: string,
    height: string,
    theme: Theme,
    logoRefCallback?: (elm: React.Ref) => void,
}

function EventLogo (props: Props) {
    return React.createElement(props.theme.logo, {
        width: props.width,
        height: props.height,
        ref: props.logoRefCallback ? elementRef => {
            props.logoRefCallback(elementRef)
        } : undefined,
    })
}

export default withTheme(supportedThemes)(EventLogo)
