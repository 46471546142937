// @flow
import React from 'react'
import Modal from 'components/Modal'

import styles from './styles.module.scss'

type Props = {
    termsAndConditions: string
}

export default function TermsAndConditions (props: Props) {
    const [open, setOpen] = React.useState(false)

    return <>
        <button className={styles.button} onClick={() => setOpen(true)}>Legal Disclaimer</button>
        <Modal isOpen={open} onCancel={() => setOpen(false)}>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: props.termsAndConditions}} />
        </Modal>
    </>
}
