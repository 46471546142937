
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsb5ebc723e2081f08e9cd812e9dbb751een_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'b5ebc723e2081f08e9cd812e9dbb751e', flattenObject(nsb5ebc723e2081f08e9cd812e9dbb751een_US), true, true);
        
            import nsb5ebc723e2081f08e9cd812e9dbb751een from './en.yaml'
            i18n.addResourceBundle('en', 'b5ebc723e2081f08e9cd812e9dbb751e', flattenObject(nsb5ebc723e2081f08e9cd812e9dbb751een), true, true);
        
        export default 'b5ebc723e2081f08e9cd812e9dbb751e';
    