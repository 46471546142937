const MODELS = {
    [`Mazda.CX-30 GT/GT Turbo.2024`]: true,
    [`Mazda.Mazda3 Sport GT Turbo.2024`]: true,
    [`Mazda.CX-5 Sport/Suna/Signature.2024`]: true,
    [`Mazda.CX-50 GT Turbo/Meridian/Apex.2024`]: true,
    [`Mazda.CX-90 MHEV/PHEV.2024`]: true,
    [`Mazda.Mazda3 GT.2024`]: true,
    [`Mazda.MX-5.2023`]: true,
    [`Mazda.MX-30 EV.2023`]: true,
}

export default function isShownNotExact (name, make, year) {
    return MODELS[`${make}.${name}.${year}`]
}
