// @flow
import * as React from 'react'
import cn from 'classnames'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

function buildButtonClasses (props) {
    let classes = {
        [styles.button]: true,
        [styles.icon]: props.icon,
        [styles.isOutlined]: props.outlined,
        [styles.isInverted]: props.inverted,
        [styles.isRounded]: props.rounded,
        [styles.isLink]: props.link,
        [styles.isHover]: props.hover,
        [styles.isFocus]: props.focus,
        [styles.isActive]: props.active,
        [styles.isLoading]: props.loading,
        [styles.isDisabled]: props.disabled
    }

    if (props.color) {
        classes[`__color-${props.color}`] = true
    }

    if (props.size) {
        classes[`__size-${props.size}`] = true
    }

    if (props.padding !== 'regular') {
        classes[`__padding-${props.padding || 'none'}`] = true
    }

    return classes
}

class Button extends React.Component {
    render () {
        const props = this.props
        const loader = { '--color-loader': `url(${props.theme.loader})` }

        let content = props.children

        const classes = buildButtonClasses(this.props)

        return (
            <button
                style={{
                    ...loader,
                    '--primary-font': props.theme.primaryFont,
                    ...props.style
                }}
                type={props.isSubmit ? 'submit' : 'button'}
                disabled={props.disabled}
                onClick={props.onClick}
                className={cn(classes, this.props.className)}>
                {content}
            </button>
        )
    }
}

Button.defaultProps = {}

export default withTheme(supportedThemes)(Button)

export const ButtonGroup = ({ children, className }: { children: React.Node, className: String}) => (
    <div className={`sh-button-group ${className}`}>{children}</div>
)

ButtonGroup.defaultProps = {
    className: '',
}
