import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store'
import cn from 'classnames'
import Seedrandom from 'seedrandom'

import * as Form from 'components/Form'
import BottomBar from 'components/BottomBar'
import WideCarView from 'components/WideCarView'

import get360VehicleImageUrl from 'util/get360VehicleImageUrl'

import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

import withForm from 'components/Form/Helpers/FormHOC'

import styles from './styles.module.scss'

const INITIAL_VALUE = 'Filter all types'
const INITIAL_NAME_VALUE = 'Filter by vehicle'
const INITIAL_MAKE_VALUE = 'Filter all makes'

function Step2 ({ theme, isLoading, handleFieldsChange, values }) {
    const { state } = React.useContext(Store)
    const { t } = useTranslation(transDomain)
    const [segment, setSegment] = React.useState(null)
    const [name, setName] = React.useState(null)
    const [make, setMake] = React.useState(null)
    const [model, setModel] = React.useState(null)
    const campaignModels = React.useMemo(() => {
        return state.campaign.models.map(model => {
            if (state.campaign.incentives.voucher.type !== 'variable') {
                return {
                    ...model,
                    voucherValue: state.campaign.incentives.voucher.value
                }
            }
            return model
        })
    }, [state.campaign.models, state.campaign.incentives.voucher.type, state.campaign.incentives.voucher.value])

    const segments = React.useMemo(() => {
        const segments = campaignModels.reduce((ss: Set, model) => {
            if (model.segment !== null) {
                ss.add(model.segment)
            }
            return ss
        }, new Set())
        return Array.from(segments).sort((a, b) => {
            if (a === 'Pre-Owned') {
                return 1
            }

            if (b === 'Pre-Owned') {
                return -1
            }

            return a < b ? -1 : 1
        })
    }, [campaignModels])

    const makes = React.useMemo(() => {
        const make = {}
        campaignModels.forEach(m => {
            if (segment && m.segment !== segment) return false
            make[m.make] = m.make
        })
        return Object.keys(make)
    }, [campaignModels, segment])

    const originalMakes = React.useMemo(() => {
        const make = {}
        campaignModels.forEach(m => {
            make[m.make] = m.make
        })
        return Object.keys(make)
    }, [campaignModels])

    const models = React.useMemo(() => {
        return campaignModels.filter((m) => {
            if (segment && m.segment !== segment) return false
            if (make && m.make !== make) return false
            return !(name && m.name !== name)
        })
    }, [campaignModels, segment, make, name])

    const handleSetSegment = React.useCallback(seg => {
        setSegment(seg)
    }, [])

    const handleSelect = React.useCallback(
        async (make, model, year, color) => {
            handleFieldsChange({
                newVehicleMake: make,
                newVehicleModel: model,
                newVehicleModelYear: year,
                newVehicleModelColor: color
            })
        },
        [handleFieldsChange]
    )

    const defaultModel = React.useMemo(() => {
        return models.find(model => model.name === values.newVehicleModel && model.year === values.newVehicleModelYear)
    }, [models, values.newVehicleModel, values.newVehicleModelYear])

    React.useEffect(() => {
        if (!models[0] || model) return () => {}

        if (defaultModel) {
            const rng = new Seedrandom(
                [
                    defaultModel.make,
                    defaultModel.name,
                    defaultModel.year
                ].join('-')
            )
            let color = theme.vehicleColors.sort(() => rng.quick() - 0.5)
            color = Array.isArray(color) ? color.join(',') : color
            setModel({ ...defaultModel, color })
        } else {
            const preSelectedModel = models[0]
            const rng = new Seedrandom(
                [
                    preSelectedModel.make,
                    preSelectedModel.name,
                    preSelectedModel.year
                ].join('-')
            )
            let color = theme.vehicleColors.sort(() => rng.quick() - 0.5)
            color = Array.isArray(color) ? color.join(',') : color
            setModel({
                voucherValue: preSelectedModel.voucherValue,
                startingPrice: preSelectedModel.startingPrice,
                make: preSelectedModel.make,
                name: preSelectedModel.name,
                year: parseInt(preSelectedModel.year, 10),
                color,
                index: 0
            })
        }
    // eslint-disable-next-line
  }, [models, theme.vehicleColors, defaultModel]);

    React.useEffect(() => {
        if (model) {
            const { make, name, year, color } = model
            handleSelect(make, name, year, color)
        }
    }, [model, handleSelect, state.lead])

    React.useEffect(() => {
        let resetNameFlag = true
        let resetMakeFlag = true

        models.forEach(m => {
            if (m.make === make) {
                resetMakeFlag = false
            }

            if (m.name === name) {
                resetNameFlag = false
            }
        })

        if (resetMakeFlag) {
            setMake(null)
        }

        if (resetNameFlag) {
            setName(null)
        }
    }, [models, segment, name, make])

    return (
        <>
            <div className={styles.layoutWrapper}>
                <h2
                    className={styles.heading}
                    style={{
                        color: theme.titleColor
                    }}
                >
                    {t('heading')}
                </h2>
                <div className={cn(styles.dropdown, {
                    [styles.wideFilterView]: originalMakes.length > 1
                })}>
                    <Form.Row>
                        <Form.RowColumns>
                            <Form.RowColumn>
                                <Form.Field>
                                    <Form.Dropdown
                                        options={segments}
                                        trans={t}
                                        placeholder={INITIAL_VALUE}
                                        onChange={handleSetSegment}
                                        defaultValue={segment || INITIAL_VALUE}
                                        required={false}
                                    />
                                </Form.Field>
                            </Form.RowColumn>
                            {originalMakes.length > 1 && (
                                <Form.RowColumn>
                                    <Form.Field>
                                        <Form.Dropdown
                                            options={makes}
                                            trans={t}
                                            placeholder={INITIAL_MAKE_VALUE}
                                            onChange={setMake}
                                            defaultValue={make || INITIAL_MAKE_VALUE}
                                            required={false}
                                        />
                                    </Form.Field>
                                </Form.RowColumn>
                            )}
                            <Form.RowColumn>
                                <Form.Field>
                                    <Form.ModelPickerDropdown
                                        placeholder={INITIAL_NAME_VALUE}
                                        models={models}
                                        defaultModel={defaultModel}
                                        required={false}
                                        onChange={value => {
                                            if (value) {
                                                setModel(value)
                                            }
                                        }}
                                    />
                                </Form.Field>
                            </Form.RowColumn>
                        </Form.RowColumns>
                    </Form.Row>
                </div>

                <div className={styles.stepContainer}>
                    <WideCarView
                        title={model
                            ? `${model.year || ''} ${model.name} ${segment === 'Pre-Owned' ? model.make : ''}`
                            : '...'
                        }
                        startingPrice={model ? model.startingPrice : 0}
                        segment={segment}
                        make={model ? model.make : ''}
                        voucherValue={model ? model.voucherValue : 0}
                        uri={model ? get360VehicleImageUrl(model.make, model.name, model.year, 'jpg') : null
                    }/>
                </div>
            </div>
            <BottomBar
                step={1}
                fixed
                isLoading={isLoading}
            />
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step2))
