function get360VehicleImageUrl (
    make: string,
    name: string,
    year: ?number,
    format: string = 'png'
) {
    return encodeURI(`${process.env.REACT_APP_VEHICLE_IMAGES_BASE_URL}exterior-360/${make}.${name}.${year}.${format}?fallbackToStillImage=1`)
}

export default get360VehicleImageUrl
