// @flow
import React from 'react'
import { withTranslation } from 'react-i18next'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

const LoadingIndicator = ({
    theme,
    noAnimated,
    small,
    t
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.loaderOpacity} />
            <span
                className={cn(styles.offerTag, {
                    [styles.noAnimated]: noAnimated,
                    [styles.small]: small
                })}
                style={{
                    backgroundColor: theme.backgroundColor,
                    color: theme.color,
                    '--primary-font': theme.primaryFont
                }}
                >
                {t('offer')}
            </span>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(LoadingIndicator))
