
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsdf1117cdf038a7eb5ad14885992ffce7en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'df1117cdf038a7eb5ad14885992ffce7', flattenObject(nsdf1117cdf038a7eb5ad14885992ffce7en_US), true, true);
        
            import nsdf1117cdf038a7eb5ad14885992ffce7en from './en.yaml'
            i18n.addResourceBundle('en', 'df1117cdf038a7eb5ad14885992ffce7', flattenObject(nsdf1117cdf038a7eb5ad14885992ffce7en), true, true);
        
        export default 'df1117cdf038a7eb5ad14885992ffce7';
    